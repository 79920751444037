import React from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
// import {auth} from '../../utils/firebase.js';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebase from 'firebase/app';
// import "firebase/auth";
// import Loader from '../../components/WidgetComponents/Loader'
// import {navigate} from 'gatsby'
// import signInButton from "../images/signin.png"
import Layout from "../../components/layout"
import {navigate} from "gatsby"
import Confetti from 'react-confetti'

// const useStyles = makeStyles(theme => ({
//   signInButton: {
//     width: '200px',
//     padding: '0px',
//     margin: '0px',
//      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
//     filter: 'drop-shadow(0px 3px 8px rgba(0,0,0,0.35))',
//     transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
//     '&:hover': {
//       filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.2)) brightness(90%)',
//
//        boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
//        '-webkit-filter': 'drop-shadow(5px 5px 5px #222)',
//        filter: 'drop-shadow(0px 3px 5px rgba(0,0,0,0.4))',
//        filter: 'brightness(90%)',
//       cursor: 'pointer'
//     }
//   }
// }));

if (typeof window !== `undefined`) {
  // getFirebase()
  WebFont.load({
    google: {
      families: 'Work Sans:lighter,normal,bold,bolder'
    }
  });
}

// const auth = firebase.auth;

// function signInWithGoogle() {
//   const provider = new auth.GoogleAuthProvider();
//
//   const state = {
//     'redirected': true
//   }
//   const title = ''
//   const url = '/app/authenticating'
//   if (typeof window !== `undefined`) {
//     window
//       .history
//       .pushState(state, title, url)
//   }
//
//   return auth().signInWithRedirect(provider);
// }

// async function googleSignIn() {
//   try {
//     await signInWithGoogle();
//   } catch (error) {
//     console.log('Error')
//      this.setState({error: error.message});
//   }
// }

function Celebrate({
  location
}, props) {
  // const [loaded, setLoaded] = useState(false)
  // const [activeUser, setActiveUser] = useState(null)
  // const [isSignedIn, setIsSignedIn] = useState(null)

  // const classes = useStyles();

  // useEffect(() => {
  //   firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //        setLoaded(true);
  //       if (user) {
  //         console.log('Yeet')
  //       } else {
  //          localStorage.setItem("onSignIn", "true");
  //         console.log('Nope')
  //       }
  //     })
  // })

  // const uiConfig = {
  //    Display Google as auth provider.
  //   signInFlow: 'redirect',
  //    signInSuccessUrl: '/app/waitlist',
  //   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  //   callbacks: {
  //      uiShown: function() {
  //         The widget is rendered.
  //         Hide the loader.
  //        alert('yeet')
  //      }
  //       Avoid redirects after sign-in.
  //     signInSuccessWithAuthResult: () => false
  //   }
  // };

  const page = (<Layout>
    <Grow in="in">
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: '',
          width: '100vw',
          height: '100vh'
        }}>
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            backgroundColor: '',
            maxWidth: '950px',
            height: '100vh'
          }}>
          <Typography id="share-dialog" style={{
              textAlign: "center",
              fontSize: '60px',
              fontWeight: 'bold',
              fontFamily: 'Work Sans',
              margin: '-10px 0px 5px 0px',
              lineHeight: 1
            }}>{'Looking good! '}
            {<span role="img" aria-label="Avatar">🎉</span>}</Typography>
          <Typography id="share-dialog" style={{
              textAlign: "center",
              color: '#7a7a7a',
              fontSize: '30px',
              fontWeight: '600',
              fontFamily: 'Work Sans',
              margin: '22px 0px 10px 0px',
              lineHeight: 1
            }}>Congrats, you just created a new style guide for your brand. Now get out there and start sharing your style.</Typography>
          <Button size='large' variant='contained' color='secondary' onClick={event => navigate(location.state.brandURL)} style={{
              // color: 'grey',
              margin: '22px 10px 10px',
              fontSize: '18px'
            }}>Check Out My Brand</Button>
        </Grid>
      </Grid>
    </Grow>
    <Confetti/>
  </Layout>)

  return (page)

  // return (<div>
  //   <h1>My App</h1>
  //   <p>Welcome {
  //       firebase
  //         .auth()
  //         .currentUser
  //         .displayName
  //     }! You are now signed-in!</p>
  //   <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
  // </div>)
  // return <Loader/>
}

export default Celebrate

// <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
